.modal-overlay {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Rufina;
    color: blue;
}

.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    font-family: Rufina;
    width: 80%; /* Could be more or less, depending on screen size */
    max-width: 500px; /* Maximum width */
    text-align: center; /* Center the text inside the modal */
    color: black;
}

button {
    margin: 10px;
    padding: 10px;
}

.language-button {
    margin: 5px; /* Adjust margin for spacing between buttons */
    padding: 10px 20px; /* Adjust padding for button size */
    font-size: 18px; /* Increase font size */
    font-family: Rufina;
    font-weight: bold;
    color: white; /* Change text color to white */
    background-color: #C95B0C; /* Set your desired background color */
    border: none; /* Remove border */
    border-radius: 5px; /* Add border radius for rounded corners */
    cursor: pointer; /* Change cursor to pointer on hover */
    transition: background-color 0.3s ease; /* Smooth transition for background color */
}

