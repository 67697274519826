/* If you're using a separate CSS file */
.scroll-button {
    position: fixed; /* Fixed positioning */
    z-index: 1000; /* Ensure it stays on top of other content */
    cursor: pointer; /* Optional: Changes the cursor on hover */
    color: brown;
}

.scroll-to-top {
    bottom: 20px;
    right: 20px;
}

.scroll-to-bottom {
    bottom: 20px;
    left: 20px;
    
}

/* width: calc(100vw / 7); /* Set width to 1/7th of the viewport width */
/*height: calc(100vw / 7); /* Set height to match the width for a square button */