/* @font-face {
    font-family: 'CustomFont';
    src: url('../../Downloads/Single_Day/SingleDay-Regular.ttf') format('truetype');
} */

@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Open+Sans:wght@400;700&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, .section-button {
    font-family: 'Rufina', serif;
}



.guide-to-cart {
    font-weight: normal;
    text-align: center;
    margin: 20px 5px;
    font-size: 20px;
    color: #65350F;
}


.menu-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* font-family: monospace; */
    /* font-family: 'CustomFont', sans-serif; */
}

.menu-item {
    margin-bottom: 20px;
    /* font-family: monospace; */
    /* font-family: 'CustomFont', sans-serif; */
}




.menu-image {
    width: 100%; /* Make the image fill the container */
    height: 150px; /* Fixed height */
    object-fit: cover; /* Cover the area, can adjust as needed */
}

.menu-image-container {
    width: 100%;
    height: 200px;
    background-position: center;
    background-size: cover; /* or 'contain' */
    background-repeat: no-repeat;
}



.menu-text {
    text-align: center;
    margin: 5px 10px;
    font-size: 14px;
    font-family: 'Open Sans';
    margin-bottom: 0px;
    /* font-family: 'CustomFont', sans-serif; */
}

.language-buttons button {
    padding: 12px 24px;
    font-size: 20px;
    margin: 10px 10px;
    /* background-color: #007bff; */
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
    /* font-family: monospace; */
    /* font-family: 'CustomFont', sans-serif; */
}


.language-buttons button {
    appearance: none;
    /* background-color: #2ea44f; */
    background-color: #D08770;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
  }

/* .language-buttons button:hover {
    background-color: #0056b3;
} */
.section-button {
    appearance: none;
    background-color: rgba(51, 51, 51, 0.05);
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #333333;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    margin: 2px;
}

.section-button:hover {
  box-shadow: rgba(44,187,99,.35) 0 -25px 18px -14px inset,rgba(44,187,99,.25) 0 1px 2px,rgba(44,187,99,.25) 0 2px 4px,rgba(44,187,99,.25) 0 4px 8px,rgba(44,187,99,.25) 0 8px 16px,rgba(44,187,99,.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}

.scroll-to-cart-btn {
    appearance: none;
    background-color: orange;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system, system-ui, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    /* Additional styles for centering or other adjustments */
}



.warning-box {
    background-color: #fff4e5;
    border: 1px solid #ffcc00;
    border-radius: 5px;
    padding: 15px;
    margin: 20px 5px;
    color: #333;
}
.logo-image {
    width: 150px;
}
.menu-item-price {
    font-weight: bold;
    color: #65350F;
}

.review-promotion {
    font-weight: bold;
    text-align: center;
    margin: 10px auto; /* Adjusted margin */
    padding: 10px; /* Adjusted padding */
    font-size: 15px; /* Adjusted font size */
    color: #fff; /* White text color */
    background-color: #FDAE44; /* Adjusted background color */
    border-radius: 10px; /* Increased border radius */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    width: 80%; /* Adjusted width */
    border: 3px solid brown;
}

.review-promotion a {
    color: #ffffff; /* White color for the link */
    font-family: Rufina;
    text-decoration: underline;
    font-size: 16px;
    font-weight: bold;
    padding: 3px 7px;
    background-color: #e67e22; /* Darker orange for the link */
    border-radius: 5px; /* Rounded corners for the link */
    transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.review-promotion a:hover {
    background-color: #FDAE44; /* Adjusted background color on hover */
}

.review-promotion h2 {
    margin-top: 0; /* Remove top margin for the title */
    font-size: 20px; /* Increase font size for the title */
}



.cart-notification {
    color: #e97042; /* Example color */
    font-weight: normal;
    font-size: 13px;
    margin-top: 3px; /* Adjust as needed to create space between the button and the notification */
    text-align: center; /* This ensures the text is centered under the button */
  }
  

.add-to-cart-button {
    
    color: white; /* Use 'color', not 'font-color' */
    padding: 5px 10px; /* Example padding */
    font-size: 13px; /* Example font size */
    background-color: brown; /* Example background color */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}


.custom-select {
    background-color: #f0f0f0; /* Light grey background */
    border: 1px solid #ccc; /* Light grey border */
    padding: 2px; /* Spacing inside the dropdown */
    margin-top: 5px; /* Space above the dropdown */
    margin-bottom: 5px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 14px; /* Text size */
    border-radius: 4px; /* Rounded corners */
    cursor: pointer; /* Change cursor to indicate it's clickable */
}
  
/* .dropdown-container {
    font-family: Arial, sans-serif;
    background-color: #f5f5f5;
    display: flex;
    
    align-items: center;
    justify-content: space-between;
    align-items: center;
    position: relative;
} */

.custom-select__control {
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    /* React Select already applies padding and font size, but you can adjust them here if needed */
    border-radius: 2px;
    cursor: pointer;
    width: 250px; /* Set a fixed width */
    /* or use max-width for responsive width control */
    max-width: 90%; /* Example: Makes the dropdown responsive but not wider than 90% of its container */
    /* Retain other styles */
}

.custom-select__menu {
    /* Style the dropdown menu */
}

.custom-select__option {
    /* Style individual options */
}

/* Add more as needed, targeting other parts of the React Select */

.select-wrapper {
    display: flex;
    justify-content: center;
    width: 100%; /* Ensures the wrapper takes full width of its parent */
}
